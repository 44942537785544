import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import banner from "../../assets/images/filimint-banner.png";
import PartnerModal from "./PartnerModal";

const Serviceprovider = () => {
  // const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main_home_section idependent_cotractor">
        <div className="three_page ">
          <div className="banner_section register ">
            <div className="inner_banner">
              <div className="container-fluid">
                <div className="inner_baner_class">
                  <div className="row">
                    <div className=" col-lg-5">
                      <div className="two_side">
                        <div className="tagline">
                          <h1>Added Value for Your 1099 Customers</h1>
                          <p className="service_sub_heading subtitle">
                            Embedded Insurance for Your Platform{" "}
                          </p>
                          <p className="service_heading_text">
                            Partner with Filimint to bring simple and flexible
                            health Insurance to your customers via a product
                            portfolio Platform that works with yours.
                          </p>
                          <p className="service_heading_text">
                            Filimint helps deliver answers to “How do I ensure
                            we are Offering the most comprehensive solution to
                            our customers workers”.
                          </p>

                          {/* <button type="button" className=" btn_yellow mt-3 mb-2" >Become a Partner</button> */}
                          <button
                            type="button"
                            className=" btn_yellow mt-3 mb-2"
                            data-bs-toggle="modal"
                            data-bs-target="#partnermodal"
                          >
                            Become a Partner
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-7">
                      <div className="outer_image">
                        <div className="banner_image">
                          <div className="banner_main_image">
                            <img className="" src={banner} alt="banner_image" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="every_independent section_spcing section_bg">
            <div className="container">
              <div className="title text-center">
                <h2>
                  <span>The Benefits</span> of Partnering
                </h2>
              </div>
              <div className="content_block">
                <p className="title-txt">
                  Adding digital insurance to your platform can be an awesome
                  value add for your customers and you. Filimint can offer:
                </p>

                <div className="tagline  ">
                  <ul>
                    <li>
                      <p>Generate additional revenue through your platform.</p>
                    </li>
                    <li>
                      <p>
                        Increased depth of your relationship with customers.
                      </p>
                    </li>
                    <li>
                      <p>
                        Provide customers (IC’s or Employers) with full scope
                        product availability.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="every_independent section_spcing">
            <div className="container">
              <div className="title text-center">
                <h2>
                  <span>100% Digital</span> Insurance – Including Mobile App
                </h2>
              </div>
              <div className="content_block">
                <p className="title-txt">
                  Filimint offers full end to end insurance for 1099 workers to:
                </p>

                <div className="tagline">
                  <ul>
                    <li>
                      <p>File Claims.</p>
                    </li>
                    <li>
                      <p>View Policy Information, claim & billing history.</p>
                    </li>
                    <li>
                      <p>Instant certificates of insurance.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PartnerModal />
    </>
  );
};
export default Serviceprovider;
