import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
const Employers = () => {
    // const navigate = useNavigate();

    useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

    return (
        <div className="main_home_section idependent_cotractor section_spcing" >
            <div className="three_page ">
                <div className="container">
                    <div className="two_side content_block">
                        <div className="title text-center">
                            <h2><span>If you hire</span> independent contractors who don’t have their own insurance,you’re running a risky business.</h2>
                        </div>
                        <div className="tagline  ">
                            <ul>
                                <li> <p> And most employers with 1099 contractors have no idea what the risk is.</p></li>
                                <li> <p>If left unchecked, it could force your business to change in radically undesirable ways — or simply shut down.</p></li>
                                <li>                        <p>The risk can’t be fully eliminated. But with help from Filimint, it can be mitigated</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="every_independent section_spcing section_bg">
                    <div className="container">
                        <div className="title text-center">
                            <h2><span>Employers with</span> independent contractors have more legal obligations than they realize.</h2>
                        </div>
                        <div className="content_block">
                            <ul>
                                <li> <p>If your 1099 contractors get injured and don’t have insurance, you’ll probably
                                    have to foot the bill.</p></li>
                                <li> <p>Often, employers are as responsible for injured independent contractors as
                                    injured employees.</p></li>
                                <li>  <p>The law has made this increasingly clear.</p></li>
                                <li> <p>Court cases across the nation have shown that it’s easy for independent
                                    contractors to sue their employers and win.</p></li>
                                <li> <p>What does that mean for your business?</p></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="every_independent section_spcing">
                    <div className="container">
                        <div className="title text-center">
                            <h2><span>What happens</span> when your independent
                                contractors get injured — and have no
                                insurance?</h2>
                        </div>
                        <div className="content_block">
                            <p>
                                After you’re sued, the law will force you to recognize your independent
                                contractors as W-2 employees.</p>

                            <p><b>With that, you’ll be responsible for:</b>

                                <ul className="after_send mt-4">
                                    <li><p>Employee health insurance</p></li>
                                    <li><p>Overtime pay</p></li>
                                    <li><p>Sick leave</p></li>
                                    <li><p>Family leave</p></li>
                                    <li><p>Vacation time</p></li>
                                    <li><p>Payroll taxes</p></li>
                                </ul></p>

                            <p> And everything else W-2 employees are entitled to.</p>
                            <p>If you’ve modelled your business to rely on 1099 contractors, worker
                                reclassification is a heavy burden.</p>
                            <p>It’s one of many reasons why you should require independent contractors to
                                purchase insurance for themselves.</p>
                            <p>When your workers buy insurance, you’re less likely to get sued when
                                accidents occur.</p>

                        </div>
                        
                        <Link to="/independent-contractor">
                            <button type="button" className=" btn_yellow mt-5 mb-2 learn_help">Learn How Filimint Helps Independent Contractors</button>
                        </Link>
                        
                    </div>
                </div>
              
            </div>
        </div>
    )

}
export default Employers;