import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import occupational_accident from "../../assets/images/occupational_accident.png";
import contingent_liability from "../../assets/images/enhance-insurance-bundles.png";
import limited_medical from "../../assets/images/limited_medical.png";


const IndependentContractor = () => {
    // const navigate = useNavigate();

    useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

    const ourProject = [
        {
            image: occupational_accident,
            heading: 'Occupational Accident',
            description: 'When a workplace injury prevents you from working, occupational accident insurance can help you and your family keep money in the bank.',
            button: false

        },
        {
            image: contingent_liability,
            heading: 'Enhanced Bundle',
            description: 'For one low price, get coverage for dental, vision, behavioural health, prescriptions, and financial wellness.',
            button: false
        },
        {
            image: limited_medical,
            heading: 'Limited Medical',
            description: 'When you get sick or injured while you’re between jobs, limited medical insurance can cover you.',
            button: false
        }
    ]


    return (
        <div className="main_home_section idependent_cotractor section_spcing" >
            <div className="three_page ">
            <div className="container">
                <div className="two_side content_block">
               
                    <div className="tagline text-center ">
                        <h1>Simple, fast insurance for independent contractors.</h1>
                        <p>Protect yourself from the worst-case scenario. Get a free quote in minutes</p>
                        <Link to="/coming">
                            <button type="button" className=" btn_yellow mt-5 mb-5 mb-2">Get My Quote</button>
                        </Link>
                        
                    </div>
                    </div>
                </div>
                <div className="every_independent section_spcing section_bg">
                    <div className="container">
                        <div className="title text-center">
                            <h2><span>Every independent</span> contractor needs insurance. But how many have it?</h2>
                        </div>
                        <div className="content_block">

                            <p>
                                As an independent contractor, you have no safety net but the one you’ve built
                                for yourself.</p>
                            <p>What will you do if unexpected circumstances stop you from working?</p>
                            <p>A broken bone in your dominant hand.</p>
                            <p>A pinched nerve from a fender bender.	</p>
                            <p>An illness that puts you out of commission for more than a few days.</p>
                            <p>Independent contractors are one of America’s most vulnerable workforces —
                                and Filimint was made to help them get the insurance they need.</p>

                        </div>
                    </div>
                </div>
                <div className="every_independent section_spcing">
                    <div className="container">
                        <div className="title text-center">
                            <h2><span>Filimint offers</span> insurance policies with
                                independent contractors in mind.</h2>
                        </div>
                        <div className="content_block">
                            <p>
                                Filimint was created because independent contractors had nowhere to find
                                insurance tailored to them.</p>
                            <p>Before Filimint, your only options would be to:</p>
                            <p><ul>
                                <li><p>Hunt down an insurance specialist, set up a one-to-one meeting, and
                                    hope they give you a good deal, or…</p></li>
                                <li><p>Get a bloated, over-priced insurance policy full of unnecessary features
                                    designed to help larger businesses, not independent contractors.</p></li>
                            </ul></p>
                            <p>With Filimint, it’s easy to get an insurance policy that fits your unique needs.</p>
                            <p>All you have to do is click a few buttons, answer a few questions, and you’ll
                                have your free quote within minutes.</p>
                            <p>No matter what type of work you do, if you’re registered as a 1099 contractor,
                                Filimint can get you covered quickly and easily.</p>

                            <p>Truck drivers, Construction workers, Freelancers, and everyone else.</p>

                            <p>You can get the insurance you need to safely move on with your work right
                                now.</p>
                            <Link to="/coming">
                                <button type="button" className=" btn_yellow mt-5 mb-2">Get My Quote</button>
                            </Link>
                            
                        </div>
                    </div>
                </div>

                <div className="every_independent section_spcing products_section section_bg">
                    <div className="container">
                        <div className="title text-center">
                            <h2><span>What types</span> of insurance can you get from
                                Filimint?</h2>
                        </div>
                        <div className=" text-center ">
                            <div className="sub_text">
                            <p>
                                Bad luck can strike at any moment. When it does, an insurance policy makes
                                a world of difference.</p>
                            <p>Protect yourself and your family with the following insurance products.</p>
                            </div>

                            <div className="row">
                                {
                                    ourProject && ourProject.map((project, key) => {
                                        return (
                                            <div className=" col-md-4 mt-5" key={key}>
                                                <div className="card_block text-center oneblokc">
                                                    <img src={project.image} className="img-fluid" alt="" />
                                                    <div className="card_detail">
                                                        <h3>{project.heading}</h3>
                                                        <p>{project.description}</p>
                                                    </div>
                                                    {project.button && project.button == true ? <button type="button" className=" btn_yellow">Know More</button> : ''}
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            
                            
                            <Link to="/coming">
                                <button type="button " className=" btn_yellow mt-5 mb-2">Get My Quote</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="every_independent section_spcing products_section">
                    <div className="container">
                        <div className="title text-center">
                            <h2><span>When the</span> unexpected happens, you’ll want to be protected.</h2>
                        </div>
                        <div className="content_block text-center">
                            <p>With Filimint, you can go from having nothing to being insured in just a few
                                minutes.</p>
                            <p>Get a quote today and see how easy it is to get affordable, flexible insurance.</p>
                            
                            <Link to="/coming">
                                <button type="button " className=" btn_yellow mt-5 mb-2">Get My Quote</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default IndependentContractor;