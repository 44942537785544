import React from 'react';
import Modal from "react-modal"

import '../../assets/css/modal.css';

export default ({ isOpen, onRequestClose,project }) =>

  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Modal"
    className={{
      base: 'modal-base',
      afterOpen: 'modal-base_after-open',
      beforeClose: 'modal-base_before-close'
    }}
    overlayClassName={{
      base: 'overlay-base',
      afterOpen: 'overlay-base_after-open',
      beforeClose: 'overlay-base_before-close'
    }}
    shouldCloseOnOverlayClick={true}
    closeTimeoutMS={2000}
  >
    
    <div className="custome_modal">
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{project.heading}</h5>
              <button type="button" className="btn-close" data-bs-toggle="modal" onClick={onRequestClose} data-bs-target="#staticBackdrop"><ti className="ti ti-close"></ti></button>
            </div>
            <div className="modal-body" dangerouslySetInnerHTML={{__html: project.description}}>
              {/* <p>{project.description}</p> */}
            </div>
          {/* <div className="modal-footer">
            <button type="button" className=" btn_yellow" data-bs-toggle="modal" onClick={onRequestClose} data-bs-target="#staticBackdrop">Ok</button>
          </div> */}
        </div>
    </div>
    

  </Modal>
