import React, { useState,useEffect } from "react";
import comming from "../../assets/images/Group-162266.png";
import LearningMore from "./LearningMore";
import useAnalyticsEventTracker from "../common/useAnalyticsEventTracker";
const Commingsoon = () =>{
    const gaEventTracker = useAnalyticsEventTracker('join filimint');
    useEffect(() => {
		window.scrollTo(0, 0)
        
	  }, [])

    return(
        <>
            <div className="main_section">
                <div className="inner_section">
                <div className="access_content">
                <div className="text_block">
                        <p>Access to Coverage will be available in 2022</p>
                        <p>Leave your information and we will reach out to you when available.</p>
                        <button type="button" className=" btn_yellow" data-bs-toggle="modal" data-bs-target="#bannermodal" onClick={()=>gaEventTracker('click','Connect with Us')}>Connect with Us</button>
                     
                    </div>
                </div>
                
                    <div className="image_blokces">
                        <img className="img-fluid" src={comming} alt="img"/>
                    </div>
                </div>
            </div>
            <LearningMore/>
        </>
    )

}
export default Commingsoon;