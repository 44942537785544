import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import phone from "../../assets/images/phone.png";
import contactus from "../../assets/images/contact_us_image.png";
import occupational_accident from "../../assets/images/occupational_accident.png";
import contingent_liability from "../../assets/images/enhance-insurance-bundles.png";
import limited_medical from "../../assets/images/limited_medical.png";
import indcontaractor from "../../assets/images/user_laptop.png";
import businessman from "../../assets/images/Businessman-cuate.png";
import profesional from "../../assets/images/professional.png";
import LearningMore from "./LearningMore";
import appstore from "../../assets/images/app_store.png";
import plastore from "../../assets/images/play-store.png";
import banner from "../../assets/images/filimint-banner.png"


import Lottie from 'react-lottie';
import * as logo_json from "../../assets/json/logo.json";
import * as main_json from "../../assets/json/main_anim.json";

import axios from 'axios';
import Modal from "./Modal";
import ReactGA from 'react-ga';
import useAnalyticsEventTracker from "../common/useAnalyticsEventTracker";

const Home = () => {
	const gaEventTracker = useAnalyticsEventTracker('Home');
	ReactGA.pageview(window.location.pathname + window.location.search);
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const [windowSize, setWindowSize] = useState(getWindowSize());

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}


	const defaultOptionsLogo = {
		loop: true,
		autoplay: true,
		animationData: logo_json,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	const defaultOptionsMain = {
		loop: true,
		autoplay: true,
		renderer: "svg",
		animationData: main_json,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}


	const initFormInputsContact = {
		first_name: '',
		last_name: '',
		email: '',
		contact_no: '',
		message: ''
	}
	const [showModal, setShowModal] = useState(false);
	const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
	const [clickProject, setclickProject] = useState();
	const [formInputsContact, setFormInputsContact] = useState(initFormInputsContact);
	const [error, setError] = useState([]);
	const [errorContact, setErrorContact] = useState([]);
	const [contactLoader, setContactLoader] = useState(false);
	const emailPattern = new RegExp(
		/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
	);

	const namePattern = new RegExp(/^(?:[A-Za-z]+)$/);
	const contactPattern = new RegExp(/^[0-9\b]+$/);

	const ourProject = [
		{
			image: occupational_accident,
			heading: 'Occupational Accident',
			description: 'When a workplace injury prevents you from working, occupational accident insurance can help you and your family keep money in the bank.',
			button: false

		},
		{
			image: contingent_liability,
			heading: 'Enhanced Bundle',
			description: 'For one low price, get coverage for dental, vision, behavioural health, prescriptions, and financial wellness.',
			button: false
		},
		{
			image: limited_medical,
			heading: 'Limited Medical',
			description: 'When you get sick or injured while you’re between jobs, limited medical insurance can cover you.',
			button: false
		}
	]


	const workWith = [
		{
			image: indcontaractor,
			heading: 'I’m an independent contractor',
			description: '<ul><li><p>Freelancers</p></li><li><p>Drivers</p></li><li><p>Service Professionals</p></li><li><p>Self Employed Contractors</p></li><li><p>Engineers</p></li></ul>',
			url: '/independent-contractor'
		},
		{
			image: businessman,
			heading: 'I’m an employer',
			description: '<p className="text-center">Companies that use Independent Contractors in their workforce.</p>',
			url:'/employers'
		},
		{
			image: profesional,
			heading: 'I’m a service provider',
			description: '<p className="text-center">Companies that provide services to IC Workers such as job placement, onboarding, and 1099 pay management.</p>',
			url:'/serviceprovider'
		}
	]



	const onInputChangeContact = (event) => {
		setErrorContact([]);
		setFormSubmitSuccess(false);
		formInputsContact[event.target.name] = event.target.value;
		setFormInputsContact(formInputsContact);
	};

	const validateFormContact = () => {
		setErrorContact([]);
		let error = {}
		if (!formInputsContact.first_name) {
			error.first_name = "First name is required";
		}

		if (formInputsContact.first_name && !namePattern.test(formInputsContact.first_name)) {
			error.first_name = "Please enter only character";
		}


		if (!formInputsContact.last_name) {
			error.last_name = "Last name is required";
		}
		if (formInputsContact.last_name && !namePattern.test(formInputsContact.last_name)) {
			error.last_name = "Please enter only character";
		}

		if (!formInputsContact.email) {
			error.email = "Email is required";
		}
		if (formInputsContact.email && !emailPattern.test(formInputsContact.email)) {
			error.email = "Please enter valid email address";
		}

		// if(!formInputsContact.contact_no){
		// 	error.contact_no = "Contact number is required";
		// }	
		if (formInputsContact.contact_no && formInputsContact.contact_no.length !== 10) {
			error.contact_no = "Contact number must be 10 digit";
		}
		if (formInputsContact.contact_no && !contactPattern.test(formInputsContact.contact_no)) {
			error.contact_no = "Please enter digit only";
		}

		if (!formInputsContact.message) {
			error.message = "Message is required";
		}

		if (Object.keys(error).length > 0) {
			setErrorContact(error);
			return false;
		}

		return true;
	}

	const onSubmitInterestedContact = (e) => {
		gaEventTracker('click', 'Contact')
		e.preventDefault()
		if (validateFormContact()) {
			setContactLoader(true);
			//axios.post(`https://www.admin.filimint.com/api/contact_us`, formInputsContact)
			axios.post(`${process.env.REACT_APP_API_ENDPOINT_PROD}/api/contact_us`, formInputsContact)
				.then(res => {
					setFormInputsContact(initFormInputsContact);
					setFormSubmitSuccess(true);
					setContactLoader(false);
					//console.log(res.data);

				}).catch(err => {
					setContactLoader(false);
					console.log(err);
				})

		} else {
			console.log("sorry");
		}
	}


	// Modal
	const openModalAndSetIndex = (project) => {
		setclickProject(project);
		setShowModal(true);
		return;
	};

	const closemodel = (product) => {
		setclickProject();
		setShowModal(false);
		return;
	};
	// end modal  


	return (
		<div className="main_home_section">
			<div className="banner_section register">
				<div className="inner_banner">
					<div className="container-fluid">


						<div className="inner_baner_class">
							<div className="row">
								<div className=" col-lg-5">
									<div className="two_side">
										{/* <div className="top_banner_blulb">
										  <div className="bulb_design">
										  <Lottie options={defaultOptionsLogo} className="logo"/>
										  </div>

										</div> */}
										<div className="tagline">
											<h1>Simple, fast insurance for independent contractors.</h1>
											<p>Protect yourself from the worst-case scenario. Get a free quote in minutes</p>
											{/* <button type="button" className=" btn_yellow mt-3 mb-2" data-bs-toggle="modal" data-bs-target="#bannermodal" onClick={() => { gaEventTracker('click', 'Connect with Us'); ReactGA.modalview('INTERESTED IN FILIMINT') }}>Get My Quote</button> */}
											
											<Link to="/coming">
												<button type="button" className=" btn_yellow mt-3 mb-2" >Get My Quote</button>
											</Link>
											
										</div>
										{/* <div className="title_banner">
											<div className="inner_title_banner">
												
											</div>
										</div> */}
										



									</div>
								</div>

								<div className="col-lg-7">
									<div className="outer_image" >
										<div className="banner_image">

											<div className="banner_main_image">
												{/* <Lottie options={defaultOptionsMain} /> */}
												<img className="" src={banner} alt="banner_image"/>
											</div>

										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="we_support filimint_insurance section_spcing section_bg">
				<div className="inner_we_support">
					<div className="container">
						<div className="title text-center">
							<h2><span>Filimint makes </span> getting insurance easy for every type of independent contractor.</h2>
						</div>
						<div className="content_block">
							<ul>
								<li><p>Filimint is an insurance company that sells flexible policies exclusively to independent contractors.</p></li>
								<li><p>If you’re a delivery driver, handyman, freelance writer, or any other 1099 contract worker — Filimint is here to help you protect yourself.
								</p></li>
								<li><p>Because when an accident or illness comes your way, you shouldn’t have to worry about going broke.
								</p></li>
								<li><p>As long as a Filimint policy is active, it can be applied to every job and client you work with, in all 50 states.

								</p></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="we_support section_spcing">
				<div className="inner_we_support">
					<div className="container">
						<div className="title text-center">
							<h2><span>Learn how</span> Filimint can help you</h2>
						</div>
						<div className="support_caracter ">
							<div className="row">

								{workWith && workWith.length > 0 ? workWith.map((work, windex) => {
									return (
										<div className="col-md-4 mt-5" key={windex} data-bs-target="#staticBackdrop">
											<div className="card" >
												<img src={work.image} className="card-img-top img-fluid" alt="..." />
												<div className="card-body">
														<Link to={work.url}>
															<button className=" btn_yellow">{work.heading}</button>
														</Link>
													{/* <h5 className="card-title"></h5> */}
												</div>
											</div>
										</div>
									)
								}) : null}

							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="products_section section_spcing section_bg ">
				<div className="container">
					<div className="title text-center">
						<h2> <span>Everyone needs</span> insurance sooner or later. Why not now?</h2>
					</div>
					<div class="needs_text">
						<p>Millions of independent contractors aren’t prepared for the impact an accident or illness can have on their wallets.</p>
						<p>With Filimint, you can protect yourself and your family with the following insurance products.</p>
					</div>
					<div className="row">
						{
							ourProject && ourProject.map((project, key) => {
								return (
									<div className=" col-md-4 mt-5" key={key}>
										<div className="card_block text-center oneblokc">
											<img src={project.image} className="img-fluid" alt="" />
											<div className="card_detail">
												<h3>{project.heading}</h3>
												<p>{project.description}</p>
											</div>
											{project.button && project.button == true ? <button type="button" className=" btn_yellow" data-bs-toggle="modal" onClick={() => openModalAndSetIndex(project)} data-bs-target="#staticBackdrop">Know More</button> : ''}
										</div>
									</div>
								);
							})
						}
					</div>
				</div>
			</div>

			<div className="products_section section_spcing app_section">
				<div className="container">
					<div className="download_outer">
						<div className="row">
							<div className="col-lg-6">
								<div className="left_outer">
								<div className="left_app">
								<div className="title">
									<h2> <span>Keep your </span> insurance in your pocket</h2>
								</div>
								<h3>Download the Filimint app to:</h3>
								<ul>
									<li><a>Carry proof of coverage wherever you go.</a></li>
									<li><a>Manage policies and claims with just a few finger taps.</a></li>
									<li><a>Do everything you can on the website, but mobile.</a></li>
								</ul>
								<div className="app_conect">
									<img className="img-fluid" src={plastore} alt="app" />
									<img className="img-fluid" src={appstore} alt="app" />
								</div>
								</div>
								</div>
							</div>
							<div className="col-lg-6">
							<div className="about_img">
									<img className="img-fluid" src={phone} alt="phone" />
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div className=" filimint_easy section_spcing section_bg">
				<div className="inner_we_support">
					<div className="container">
						<div className="title text-center">
							<h2><span>Get fast, flexible </span>insurance designed for independent contractors today.</h2>
						</div>
						<div className="content_block getfast">
						<p>Filimint makes it easy to get a free quote in minutes.</p>
						<p>Protect yourself and your family with an insurance policy tailored to independent contractors now.</p>
						</div>
						
						<Link to="/coming">
							<button type="button " className=" btn_yellow mt-5 mb-2">Get My Quote</button>
						</Link>
					</div>
				</div>
			</div>

			<div className="">
				<div className="contact_section bg_yellow" id="contact_page">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-6 text-center">
								 <div className="outer_contact_form">
								 <div className="contact_form title">
									<h2>Have a question? Contact us.</h2>
									<form>
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<input type="text" className="form-control bg_black" id="exampleInputFirstName" name="first_name" placeholder="First Name*"
														onChange={onInputChangeContact}
														value={formInputsContact.first_name}
														style={errorContact.first_name && errorContact.first_name ? { border: "1px solid red" } : {}}
													/>
													<span className="error">{errorContact.first_name && errorContact.first_name}</span>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<input type="text" className="form-control bg_black" id="exampleInputLastName" name="last_name"
														onChange={onInputChangeContact}
														placeholder="Last Name*"
														value={formInputsContact.last_name}
														style={errorContact.last_name && errorContact.last_name ? { border: "1px solid red" } : {}} />
													<span className="error">{errorContact.last_name && errorContact.last_name}</span>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<input type="email" className="form-control bg_black" id="exampleInputEmail" name="email"
														onChange={onInputChangeContact}
														placeholder="Email*"
														value={formInputsContact.email}
														style={errorContact.email && errorContact.email ? { border: "1px solid red" } : {}}
													/>
													<span className="error">{errorContact.email && errorContact.email}</span>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<input type="text" className="form-control bg_black" id="exampleInputcontactnumber"
														name="contact_no"
														onChange={onInputChangeContact}
														placeholder="Contact Number"
														value={formInputsContact.contact_no}
														maxLength="10"
														style={errorContact.contact_no && errorContact.contact_no ? { border: "1px solid red" } : {}} />
													<span className="error">{errorContact.contact_no && errorContact.contact_no}</span>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group">
													<textarea className="form-control bg_black" id="validationTextarea" name="message" placeholder="Message*"
														onChange={onInputChangeContact}
														value={formInputsContact.message}
														style={errorContact.message && errorContact.message ? { border: "1px solid red" } : {}}></textarea>
													<span className="error">{errorContact.message && errorContact.message}</span>
												</div>
											</div>
											<div className="col-md-12">
												<div className="form-group  submit_button">

													<button className={`btn_black ${contactLoader && contactLoader == true ? 'loader' : ''}`} onClick={onSubmitInterestedContact} disabled={contactLoader && contactLoader == true ? true : false}>
														{contactLoader && contactLoader == true ?
															<div className="spinner-border text-undefined spinner-border-sm btn-loading-spinner" role="status">
																<span className="visually-hidden">Loading...</span>
															</div>
															:
															'Submit'
														}
													</button>

													{/* {contactLoader && contactLoader == true?
													<button className="btn_black loader" disabled={contactLoader && contactLoader == true?true:false}>
														<div className="spinner-border text-undefined spinner-border-sm btn-loading-spinner" role="status">
															<span className="visually-hidden">Loading...</span>
														</div>
													</button>
													:
													<button className="btn_black" onClick={onSubmitInterestedContact} disabled={contactLoader && contactLoader == true?true:false}>
														Submit
													</button>
													} */}

												</div>
											</div>
											{formSubmitSuccess && <span className="message"><b>Thank you for contacting us.. We will get back to you shortly.</b></span>}
										</div>
									</form>
								</div>
								 </div>
							</div>
							<div className="col-lg-6 pe-0 mt-4 mt-lg-0">
								<div className="backimg">
									<div className="contact_img">
										<img src={contactus} className="img-fluid" alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{
				showModal ?
					<Modal
						isOpen={true}
						onRequestClose={closemodel}
						project={clickProject}
					/>
					: null
			}

			<LearningMore />
		</div>
	)

}
export default Home;