import React, { useState, useEffect, Fragment,useRef } from "react";
import axios from 'axios';
import rightarrow from "../../assets/images/Path 5180.png";
import useAnalyticsEventTracker from "../common/useAnalyticsEventTracker";


const LearningMore =() =>{
    //const gaEventTracker = useAnalyticsEventTracker('Home');
    const [formInputs, setFormInputs] = useState({});
    const [error, setError] = useState([]);
    const [formSubmitLearn, setFormSubmit] = useState(false);
    const [state, setState] = useState(["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]);
	const emailPattern = new RegExp(
		/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
	);
	const namePattern  = new RegExp(/^(?:[A-Za-z]+)$/);
	const contactPattern = new RegExp(/^[0-9\b]+$/);

    const onInputChangelearn = (event) => {
		setError([]);
		formInputs[event.target.name] = event.target.value;
		setFormInputs(formInputs);
		
	};


    const validateForm = () => {
		setError([]);
		let error = {}
		console.log(formInputs);


		if (!formInputs.name) {
			error.name = "Name is required";
		}

		if(formInputs.name && !namePattern.test(formInputs.name)){
			error.name = "Please enter only character";
		}

		if (!formInputs.state_name) {
			error.state_name = "Please select state";
		}

		if (!formInputs.email) {
			error.email = "Email is required";
		}
		if (formInputs.email && !emailPattern.test(formInputs.email)) {
			error.email = "Please enter valid email address";
		}

		if (!formInputs.are_you) {
			error.are_you = "Please select Are you";
		}

		/*if (!formInputs.work) {
			error.work = "Work is required";
		}*/
		
		if (Object.keys(error).length > 0) {
			setError(error);
			return false;
		}

		return true;
	}

	const onSubmitInterested = async () => {
		if (validateForm()) {
			try {
				//const respnse = await axios.post(`https://www.admin.filimint.com/api/interested_in_learning`, formInputs)
				const respnse = await axios.post(`${process.env.REACT_APP_API_ENDPOINT_PROD}/api/interested_in_learning`, formInputs)
				if (respnse.status === 200) {
					setFormSubmit(true);
				}
			} catch (error) {
				let errorr = {}
				console.log("error", error.response.data.message)
				if (error.response.data.message) {
					errorr.messageError = error.response.data.message;
					setError(errorr);
				}
			}
		} else {
			console.log("sorry");
		}
	}


    return(
        <div className="modal fade learnign_more" id="bannermodal" tabIndex="-1" aria-labelledby="bannermodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title title" id="bannermodalLabel"><h2><span>Interested</span>  in Filimint?</h2></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><ti className="ti ti-close"></ti></button>

                        </div>
                        <div className="modal-body">
                            {formSubmitLearn == false ?
                                <div className="form_banner">
                                    <div className="inner_class">
                    
                                        <div className="form_design">

                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6 mtfmar">
                                                        <input type="text" className="form-control" name="name" placeholder="Name*" onChange={onInputChangelearn}
                                                            style={error.name && error.name ? { border: "1px solid red" } : {}} />
                                                        <span className="error">{error.name && error.name}</span>
                                                    </div>
                                                    <div className="col-md-6 mtfmar">
                                                        <select id="state" className="form-select" name="state_name" onChange={onInputChangelearn}
                                                            style={error.state_name && error.state_name ? { border: "1px solid red" } : {}}>
                                                            <option value="">State *</option>
                                                            {state.map(state => {
                                                                return (
                                                                    <option key={state} value={state}>{state}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        <span className="error">{error.state_name && error.state_name}</span>
                                                    </div>
                                                    <div className="col-md-12 mtfmar">
                                                        <input type="email" className="form-control" name="email" onChange={onInputChangelearn} id="inputEmail4" placeholder="you@example.com*"
                                                            style={error.email && error.email ? { border: "1px solid red" } : {}} />
                                                        <span className="error">{error.email && error.email}</span>
                                                    </div>

                                                    <div className="col-md-12 mtfmar">
                                                        <div className="d-flex">
                                                            <label style={{ fontWeight: "600" }}>Are you : &nbsp;</label>
                                                            <div style={{ marginTop: "1px" }}>
                                        
                                                                <label className="custome_radio">Employer
                                                                    <input type="radio" value="Employer" name="are_you" onChange={onInputChangelearn} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                <label className="custome_radio">Independent contractor
                                                                    <input type="radio" value="independent contractor" name="are_you" onChange={onInputChangelearn} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <span className="error">{error.are_you && error.are_you}</span>

                                                    </div>

                                                    {/* <div className="col-md-12 mtfmar">
                                                        <label style={{ fontWeight: "600" }}>Tell us who you work for  :</label>
                                                        <input type="text" className="form-control" name="work" placeholder="Work*" onChange={onInputChangelearn} />
                                                        <span className="error">{error.work && error.work}</span>
                                                    </div> */}


                                                    <span>{error.messageError && error.messageError}</span>
                                                    <div className="col-md-12 mtfmar text-center">
                                                        <a className="a_btn" onClick={onSubmitInterested} >Get updates when Available</a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="regidter_block">
                                    <div className="content">
                                        <img className="img-fluid" src={rightarrow} alt="imge" />
                                    </div>
                                    <div className="content_detail">
                                        <p>Thank you for registering with FILIMINT.
                                            We will keep you posted with updates and news. </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
        </div>
    );

}

export default LearningMore;