import React from "react";
import logo from "../../assets/images/footer_logo.png";
import { NavLink } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

const Headersecond = () => {
    return (
        <div className="header_second_block sticky-top">
            <nav className="navbar  navbar-expand-md">
                <div className="container">
                    {/* <a className="image_log_mobile d-md-none"><a href="#"><img src={logo} alt="logo"/></a></a> */}
                    <a className="image_log_mobile d-md-none"> <NavLink
							className="nav_logo"
							to="/"
							exact>
							<img src="img/logo.svg" className="img-fluid" alt=""/>
							<img href="#" src={logo} className="img-fluid" alt=""/>
						</NavLink>
                        </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                       <i className="ti ti-menu"></i>
                    </button>
                    
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" data-bs-dismiss="offcanvas" aria-label="Close">
                    <button type="button" className="btn-close text-reset d-md-none d-block"  data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-none d-md-block">
                               <div className="header_inner_block header_second">
                                   <ul>
                                       <li><NavLink
							className="nav_logo"
							to="/"
							exact>
							<img src="img/logo.svg" className="img-fluid" alt=""/>
							<img href="#" src={logo} className="img-fluid" alt=""/>
						</NavLink></li>
                                   </ul>
                               </div>
                            </div>
                           
                          
                            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                               <div className="header_inner_block header_third">
                                   <ul>
                                   <li><NavHashLink to="/independent-contractor" activeClassName="selected"  className="nav-item">Independent Contractor</NavHashLink></li>
                                                   <li><NavHashLink to="/employers" activeClassName="selected" className="nav-item">Employers</NavHashLink></li>
                                       <li><NavLink
                                            className="nav-item"
                                            to="/serviceprovider"
                                            exact>
                                            Service Provider
                                        </NavLink></li>
                                   </ul>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

    )

}
export default Headersecond;