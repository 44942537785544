import axios from "axios";
import { useState } from "react";
import rightarrow from "../../assets/images/Path 5180.png";

const PartnerModal = () => {
  const initialData = {
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    company_url: "",
    number_of_employees: "",
    interested_in_woking: "",
  };

  const [formInputs, setFormInputs] = useState(initialData);
  const [error, setError] = useState([]);
  const [formSubmitLearn, setFormSubmit] = useState(false);
  const [state, setState] = useState([
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ]);
  const emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const namePattern = new RegExp(/^(?:[A-Za-z]+)$/);
  const contactPattern = new RegExp(/^[0-9\b]+$/);
  const urlPattern = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
  );
  const numOnly = new RegExp(/^[0-9\b]+$/); 
  const onInputChangelearn = (event) => {
    setError([]);
    formInputs[event.target.name] = event.target.value;
    setFormInputs(formInputs);
  };

  const validateForm = () => {
    setError([]);
    let error = {};

    if (!formInputs.first_name) {
      error.first_name = "first name is required";
    }
    if (formInputs.first_name && !namePattern.test(formInputs.first_name)) {
      error.first_name = "Please enter only character";
    }

    if (!formInputs.last_name) {
      error.last_name = "last name is required";
    }
    if (formInputs.last_name && !namePattern.test(formInputs.last_name)) {
      error.last_name = "Please enter only character";
    }

    if (!formInputs.email) {
      error.email = "Email is required";
    }
    if (formInputs.email && !emailPattern.test(formInputs.email)) {
      error.email = "Please enter valid email address";
    }

    if (!formInputs.company_name) {
      error.company_name = "company name is required";
    }
    if (formInputs.company_name && !namePattern.test(formInputs.company_name)) {
      error.company_name = "Please enter only character";
    }

    if (!formInputs.company_url) {
      error.company_url = "company url is required";
    }
    if (formInputs.company_url && !urlPattern.test(formInputs.company_url)) {
      error.company_url = "Please enter valid url";
    }

    if (!formInputs.number_of_employees) {
      error.number_of_employees = "number of employees is required";
    }
    
    if(!numOnly.test(formInputs.number_of_employees)){
      error.number_of_employees = "Please enter more than zero";
    }
    if (formInputs.number_of_employees<=0) {
      error.number_of_employees = "Please enter more than zero";
    }
    if (
      formInputs.number_of_employees &&
      !contactPattern.test(formInputs.number_of_employees)
    ) {
      error.number_of_employees = "Please enter only Number";
    }
    if (!formInputs.interested_in_woking) {
      error.interested_in_woking = "interested in woking is required";
    }

    if (Object.keys(error).length > 0) {
      setError(error);
      return false;
    }

    return true;
  };

  const onSubmitInterested = async () => {
    if (validateForm()) {
      try {
        const respnse = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT_PROD}/api/partner`,
          formInputs
        );
        if (respnse.status === 200) {
          setFormSubmit(true);
          setFormInputs(initialData);
        }
      } catch (error) {
        let errorr = {};
        console.log("error", error.response.data.message);
        if (error.response.data.message) {
          errorr.messageError = error.response.data.message;
          setError(errorr);
        }
      }
    } else {
      console.log("sorry");
    }
  };

  return (
    <div
      className="modal fade learnign_more"
      id="partnermodal"
      tabIndex="-1"
      aria-labelledby="partnermodalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title title" id="partnermodalLabel">
              <h2>
                <span>Become</span> a partner.
              </h2>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setFormSubmit(false);
                setFormInputs(initialData);
              }}
            >
              <ti className="ti ti-close"></ti>
            </button>
          </div>
          <div className="modal-body">
            {formSubmitLearn == false ? (
              <div className="form_banner">
                <div className="inner_class">
                  <div className="form_design">
                    <form>
                      <div className="row">
                        <div className="col-md-6 mtfmar">
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            placeholder="Fisrt Name*"
                            onChange={onInputChangelearn}
                            value={formInputs.first_name}
                            style={
                              error.first_name && error.first_name
                                ? { border: "1px solid red" }
                                : {}
                            }
                          />
                          <span className="error">
                            {error.first_name && error.first_name}
                          </span>
                        </div>
                        <div className="col-md-6 mtfmar">
                          <input
                            id="state"
                            className="form-control"
                            name="last_name"
                            placeholder="Last Name*"
                            value={formInputs.last_name}
                            onChange={onInputChangelearn}
                            style={
                              error.last_name && error.last_name
                                ? { border: "1px solid red" }
                                : {}
                            }
                          />
                          <span className="error">
                            {error.last_name && error.last_name}
                          </span>
                        </div>
                        <div className="col-md-12 mtfmar">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            onChange={onInputChangelearn}
                            value={formInputs.email}
                            id="inputEmail4"
                            placeholder="you@example.com*"
                            style={
                              error.email && error.email
                                ? { border: "1px solid red" }
                                : {}
                            }
                          />
                          <span className="error">
                            {error.email && error.email}
                          </span>
                        </div>
                        <div className="col-md-6 mtfmar">
                          <input
                            type="text"
                            className="form-control"
                            name="company_name"
                            placeholder="Company Name*"
                            onChange={onInputChangelearn}
                            value={formInputs.company_name}
                            style={
                              error.company_name && error.company_name
                                ? { border: "1px solid red" }
                                : {}
                            }
                          />
                          <span className="error">
                            {error.company_name && error.company_name}
                          </span>
                        </div>
                        <div className="col-md-6 mtfmar">
                          <input
                            id="state"
                            className="form-control"
                            name="company_url"
                            placeholder="Company Url*"
                            value={formInputs.company_url}
                            onChange={onInputChangelearn}
                            style={
                              error.company_url && error.company_url
                                ? { border: "1px solid red" }
                                : {}
                            }
                          />
                          <span className="error">
                            {error.company_url && error.company_url}
                          </span>
                        </div>
                        <div className="col-md-6 mtfmar">
                          <input
                            id="state"
                            className="form-control"
                            type="number"
                            min="0"
                            name="number_of_employees"
                            placeholder="Number of Employees*"
                            value={formInputs.number_of_employees}
                            onChange={onInputChangelearn}
                            style={
                              error.number_of_employees &&
                              error.number_of_employees
                                ? { border: "1px solid red" }
                                : {}
                            }
                          />
                          <span className="error">
                            {error.number_of_employees &&
                              error.number_of_employees}
                          </span>
                        </div>
                        <div className="col-md-12 mtfmar">
                          <input
                            className="form-control"
                            name="interested_in_woking"
                            onChange={onInputChangelearn}
                            value={formInputs.interested_in_woking}
                            id="inputEmail4"
                            placeholder="Interested In Working*"
                            style={
                              error.interested_in_woking &&
                              error.interested_in_woking
                                ? { border: "1px solid red" }
                                : {}
                            }
                          />
                          <span className="error">
                            {error.interested_in_woking &&
                              error.interested_in_woking}
                          </span>
                        </div>
                        <span>{error.messageError && error.messageError}</span>
                        <div className="col-md-12 mtfmar text-center">
                          <a className="a_btn" onClick={onSubmitInterested}>
                            SUBMIT
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="regidter_block">
                <div className="content">
                  <img className="img-fluid" src={rightarrow} alt="imge" />
                </div>
                <div className="content_detail">
                  <p>Success </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerModal;
