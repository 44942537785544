import React from "react";
import logo from "../../assets/images/footer_logo.png";
import facebook from "../../assets/images/facebook.png";
import tweeter from "../../assets/images/tweeter.png";
import pintrest from "../../assets/images/pintrest.png";
import insta from "../../assets/images/insta.png";
import linkedin from "../../assets/images/linkedin.png";
import { NavLink } from "react-router-dom";

const Footer = () =>{
    return(
        <div className="footer_section bg_black section_spcing">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="inneer_fot">
                    <div className="footer_logo">
                        <img src={logo} alt=""/>
                    </div>
                    <div className="footer_detail">
                        <div className="footer_text">
                            {/* <h2>Mese Selimovica, 52
                                78000, Banja Luka
                                BiH, U.S.A</h2> */}
                                  <div className="social_icon">
                                     <a href="https://www.linkedin.com/company/filimint/about/" target="_blank"><img src={linkedin} alt="images"/></a>
                                </div>
                            <div className="mail">
                            <h2>
                                <p>info@filimint.com </p> 
                                {/* <p><a href="tel:844-935-28765"></a>+1-844-935-28765</p> */}
                            </h2>
                            </div>
                          
                        </div>
                        <div className="priacy_block">
                        <hr/>
                        <div className="footer_plo">
                            <p>Copyright © 2022 FILIMINT INC. ALL Rights Reserved.</p>
                            {/* <p> Privacy Policy   &nbsp;   |   &nbsp;    Term of Use  </p> */}
                        <p> 
                                <NavLink
                                    target='_blank'
									className="footer-link"
									to="/privacy-policy"
									exact>
									Privacy Policy
								</NavLink>
                               &nbsp;   |   &nbsp;    
                               <NavLink
                                    target='_blank'
									className="footer-link"
									to="/term-condition"
									exact>
									Term of Use
								</NavLink>
                        </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
              
               
            </div>
        </div>
    </div>
  
    )

}
export default Footer;