
import './App.css';
import Header from '../src/component/common/Header';
import Headersecond from '../src/component/common/Headersecond';
import Footer from '../src/component/common/Footer';
import Home from '../src/component/other-component/Home';
import Commingsoon from '../src/component/other-component/Commingsoon';
import Privacypolicy from '../src/component/other-component/Privacypolicy';
import Termandcondition from '../src/component/other-component/Termandcondition';
import PageNotFound from './component/other-component/PageNotFound';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IndependentContractor from '../src/component/other-component/IndependentContractor';
import Employers from '../src/component/other-component/Employers';
import Serviceprovider from '../src/component/other-component/Serviceprovider';
//GA code
import ReactGA from 'react-ga';
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);
//end

function App() {
  return (
   <BrowserRouter>
      <Headersecond />
      <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/home" element={<Home />}/>
          <Route exact path="/coming" element={<Commingsoon />} />
          <Route exact path="/privacy-policy" element={<Privacypolicy />}/>
          <Route exact path="/term-condition" element={<Termandcondition />}/>
          <Route exact path="/independent-contractor" element={<IndependentContractor />}/>
          <Route exact path="/employers" element={<Employers/>}/>
          <Route exact path="/serviceprovider" element={<Serviceprovider/>}/>
          
          <Route path="*" element={<PageNotFound />}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
